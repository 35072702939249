import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { MantineProvider, createTheme } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'

const theme = createTheme({})

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <MantineProvider theme={theme} defaultColorScheme="light">
    <ModalsProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ModalsProvider>
  </MantineProvider>
)

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals()
