import { auth, googleProvider } from '../../config/firebase'
import { signInWithRedirect, signOut, User } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { useLoading, LoadingSpinner } from './../LoadingContext'

export const Auth: React.FC<{
  children: (
    user: User | null,
    signInWithGoogle: () => Promise<void>,
    logout: () => Promise<void>
  ) => React.ReactNode
}> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null)
  const { loading, setLoading } = useLoading()

  const signInWithGoogle = async (): Promise<void> => {
    try {
      setLoading(true)
      await signInWithRedirect(auth, googleProvider)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const logout = async (): Promise<void> => {
    try {
      setLoading(true)
      await signOut(auth)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <>
      {loading && <LoadingSpinner />}
      {children(user, signInWithGoogle, logout)}
    </>
  )
}
export default Auth
