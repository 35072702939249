import React from 'react'
import { HomeNavButton } from '../HomeNavButton'
import AdventureLogo from '../../assets/AdventureLogo.png'
import EngineeringLogo from '../../assets/EngineeringLogo.png'
import AboutMeLogo from '../../assets/AboutMeLogo.png'
import { Flex, Text, Stack, Group } from '@mantine/core'
import { Link } from 'react-router-dom'

const Home: React.FC = () => {
  return (
    <Stack>
      <Group>
        <Text c="dimmed">
          I built this site to organize my life, thoughts and utterly insane
          ramblings... Click a link below, or go fishing in the{' '}
          <Link to="/blog">blog section</Link>.
        </Text>
      </Group>
      <Flex justify="center" wrap="wrap" direction="row" gap="md">
        <HomeNavButton
          imageUrl={AdventureLogo}
          buttonText={'Adventures'}
          url={'/blog?tags=Adventures'}
        />
        <HomeNavButton
          imageUrl={EngineeringLogo}
          buttonText={'Engineering'}
          url={'/blog?tags=Engineering'}
        />
        <HomeNavButton
          imageUrl={AboutMeLogo}
          buttonText={'About Me'}
          url={'/portfolio'}
        />
      </Flex>
    </Stack>
  )
}

export default Home
