import { useEffect, useState } from 'react'
import { Image, Group, ActionIcon, Text, Center } from '@mantine/core'
import { useParams } from 'react-router-dom'
import { doc, getDoc, deleteDoc } from 'firebase/firestore'
import { db } from './../../config/firebase'
import { Post, postConverter } from './Post'
import { useNavigate } from 'react-router-dom'
import { TagList } from './../Tags'
import { FaEdit, FaTrashAlt, FaEye } from 'react-icons/fa'
import { User } from 'firebase/auth'
import { modals } from '@mantine/modals'
import { formatTimestamp } from './../Utilities/TimestampUtils'

import classes from './PostView.module.css'

import { useLoading, LoadingSpinner } from './../LoadingContext/'

interface PostViewProps {
  user: User | null
}
const PostView: React.FC<PostViewProps> = ({ user }): JSX.Element => {
  const { loading, setLoading } = useLoading()
  const [post, setPost] = useState<Post | null>(null)
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const fetchPost = async () => {
    try {
      const docRef = doc(db, 'posts', id!).withConverter(postConverter)
      setLoading(true)
      const docSnap = await getDoc(docRef)
      setLoading(false)
      if (docSnap.exists()) {
        setPost(docSnap.data())
      }
    } catch (error) {
      console.error(error)
    }
  }

  // useEffect(() => {
  //   highlightAll()
  // }, [])
  //
  const confirmDeleteModal = (postId: string) =>
    modals.openConfirmModal({
      title: 'Are you sure you want to delete this post?',
      children: <Text size="sm">This will permanently delete the post.</Text>,
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => handleDelete(postId)
    })

  const handleDelete = async (postId: string) => {
    try {
      setLoading(true)
      await deleteDoc(doc(db, 'posts', postId))
      setLoading(false)
      navigate('/blog', { replace: true, state: { refresh: true } })
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchPost()
  }, [user])

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        post && (
          <div>
            <h1>{post.title}</h1>
            <Text size="sm" c="dimmed">
              Created: <strong>{formatTimestamp(post.createdAt)}</strong> |
              Updated: <strong>{formatTimestamp(post.updatedAt)}</strong>
            </Text>
            {post.imageUrl && (
              <div>
                <Image
                  src={post.imageUrl}
                  alt="Post Image"
                  style={{ maxWidth: '100%' }}
                />
              </div>
            )}

            <TagList tags={post.tags} />
            <div
              dangerouslySetInnerHTML={{
                __html: post.content
              }}
              style={classes}
            />
            {user && (
              <Center>
                <Group>
                  <ActionIcon onClick={() => navigate(`/blog/edit-post/${id}`)}>
                    <FaEdit />
                  </ActionIcon>
                  <ActionIcon onClick={() => confirmDeleteModal(id!)}>
                    <FaTrashAlt />
                  </ActionIcon>
                  {post.visibility == 'Public' && (
                    <FaEye style={{ color: 'green' }} />
                  )}
                </Group>
              </Center>
            )}
          </div>
        )
      )}
    </div>
  )
}

export default PostView
