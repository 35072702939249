import React from 'react'
import { Image, Title, Stack, Box } from '@mantine/core'

interface HomeNavButtonProps {
  url: string
  imageUrl: string
  buttonText: string
}

const HomeNavButton: React.FC<HomeNavButtonProps> = ({
  url,
  imageUrl,
  buttonText
}) => {
  return (
    <Stack>
      <Box component="a" href={url}>
        <Image src={imageUrl} radius="xl" h={300} w="auto" />
        <Title>{buttonText}</Title>
      </Box>
    </Stack>
  )
}

export default HomeNavButton
