import { createContext, useContext, useState } from 'react'

interface LoadingContextType {
  loading: boolean
  setLoading: (loading: boolean) => void
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined)

export default function LoadingProvider({
  children
}: {
  children: React.ReactNode
}) {
  const [loading, setLoading] = useState(false)
  const value: LoadingContextType = {
    loading,
    setLoading: setLoading
  }
  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  )
}

export function useLoading() {
  const context = useContext(LoadingContext)
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider')
  }
  return context
}
