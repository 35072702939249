import { Badge } from '@mantine/core'
import { Link } from 'react-router-dom'
import classes from './TagList.module.css'

interface Props {
  tags: string[]
}

function stringToRGB(str: string) {
  const colors = [
    '#DB7093',
    '#FFA07A',
    '#FA8072',
    '#CD5C5C',
    '#DC143C',
    '#B22222',
    '#FF4500',
    '#FF7F50',
    '#FFA500',
    '#32CD32',
    '#00FF7F',
    '#00FF00',
    '#3CB371',
    '#006400',
    '#9ACD32',
    '#6B8E23',
    '#66CDAA',
    '#8FBC8F',
    '#20B2AA',
    '#008080',
    '#AFEEEE',
    '#7FFFD4',
    '#40E0D0',
    '#5F9EA0',
    '#4682B4',
    '#B0C4DE',
    '#87CEFA',
    '#00BFFF',
    '#1E90FF',
    '#6495ED',
    '#7B68EE',
    '#4169E1',
    '#0000CD',
    '#191970',
    '#F5DEB3',
    '#DEB887',
    '#F4A460',
    '#DAA520',
    '#B8860B',
    '#CD853F',
    '#D2691E',
    '#8B4513',
    '#A0522D',
    '#A52A2A',
    '#808080',
    '#696969',
    '#708090',
    '#2F4F4F'
  ]

  function hashCode(str: string) {
    let hash = 0
    if (str.length === 0) {
      return hash
    }
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i)
      hash = (hash << 5) - hash + char
      hash = hash & hash
    }
    return hash
  }

  const hash = hashCode(str)
  const index = Math.abs(hash) % colors.length
  return colors[index]
}

function TagList({ tags }: Props) {
  return (
    <>
      {tags.map((tag) => (
        <Link key={tag} to={`/blog?tags=${tag}`}>
          <Badge className={classes.badge} key={tag} color={stringToRGB(tag)}>
            {tag}
          </Badge>
        </Link>
      ))}
    </>
  )
}

export default TagList
