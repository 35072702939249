// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { GoogleAuthProvider, getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyAPX1JXY-UdCm76pBzmdI6N2hZS4ZH4qBo',
  authDomain: '3v4n.xyz',
  projectId: 'eloughlin-250b3',
  storageBucket: 'eloughlin-250b3.appspot.com',
  messagingSenderId: '85041626612',
  appId: '1:85041626612:web:00c07107a6eec4e0e40da1',
  measurementId: 'G-JXZ31YQ144'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
export const googleProvider = new GoogleAuthProvider()
export const db = getFirestore(app)
export const storage = getStorage(app)
