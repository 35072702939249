import { Timestamp } from 'firebase/firestore'

export function formatTimestamp(timestamp: Timestamp): string {
  const date = new Date(
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
  )
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }
  return date.toLocaleDateString('en-US', options)
}

export function formatDate(date: Date, includeDay: boolean): string {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: includeDay ? 'numeric' : undefined
  }
  return date.toLocaleDateString('en-US', options)
}
