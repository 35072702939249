import { useState, useEffect, ChangeEvent, FormEvent } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import tinymceInit from './../../config/tinymce-init'
import { TextInput, Button, Select, Text, Image, Input } from '@mantine/core'
import { TagPickerTool } from '../Tags'
import { postConverter } from './Post'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from './../../config/firebase'
import { useNavigate, useParams } from 'react-router-dom'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { useLoading, LoadingSpinner } from './../LoadingContext'
import { formatTimestamp } from './../Utilities/TimestampUtils'
import { Timestamp } from 'firebase/firestore'

const storage = getStorage()
const storageRef = ref(storage)

const PostEdit = () => {
  const { loading, setLoading } = useLoading()
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [visibility, setVisibility] = useState('Private')
  const [tags, setTags] = useState<string[]>([])
  const [imagePreview, setImagePreview] = useState<string>()
  const [image, setImage] = useState<File | null>(null)
  const [imageUrl, setImageUrl] = useState<string>()
  const [updatedAt, setUpdatedAt] = useState<Timestamp>()
  const [createdAt, setCreatedAt] = useState<Timestamp>()

  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    // Fetch post data from Firestore
    const fetchPost = async () => {
      try {
        console.log('This is the postid:')
        console.log(id)
        const docRef = doc(db, 'posts', id!).withConverter(postConverter)
        setLoading(true)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          const data = docSnap.data()
          setTitle(data.title)
          setContent(data.content)
          setTags(data.tags)
          setVisibility(data.visibility)
          setUpdatedAt(data.updatedAt)
          setCreatedAt(data.createdAt)
          setImageUrl(data.imageUrl)
        }
        setLoading(false)
      } catch (error) {
        console.error(error)
        navigate(`/blog`)
      }
    }

    fetchPost()
  }, [id, setLoading])

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
  }

  const handleContentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setContent(event)
  }

  const handleVisibilityChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVisibility(event)
  }

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        // Set the image preview URL
        setImage(file)
        setImagePreview(e.target?.result as string)
      }
      reader.readAsDataURL(file)
    } else {
      setImagePreview('')
    }
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const newUpdatedAt = Timestamp.fromDate(new Date())

    try {
      // Upload image to Firebase Storage if an image is selected
      let newImageUrl = imageUrl
      if (image) {
        const imageRef = ref(storageRef, `images/${image.name}`)
        setLoading(true)
        await uploadBytes(imageRef, image)
        setLoading(false)
        newImageUrl = await getDownloadURL(imageRef)
      }

      // Update post in Firestore
      setLoading(true)
      const postDoc = await doc(db, 'posts', id)
      await updateDoc(postDoc, {
        title: title,
        content: content,
        visibility: visibility,
        imageUrl: newImageUrl,
        tags: tags,
        updatedAt: newUpdatedAt
      })
      setLoading(false)

      navigate(`/blog/post/${id}`)
    } catch (error) {
      console.error('Error updating document:', error)
    }
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div>
      <h2>Edit Post</h2>
      <form onSubmit={handleSubmit}>
        {createdAt && (
          <Text c="dimmed">Created: {formatTimestamp(createdAt)}</Text>
        )}

        {updatedAt && (
          <Text c="dimmed">Last Updated: {formatTimestamp(updatedAt)}</Text>
        )}
        <TextInput
          label="Title"
          id="title"
          value={title}
          onChange={handleTitleChange}
        />

        <Text>Image Preview</Text>
        {imagePreview ? (
          <Image src={imagePreview} h={300} w="auto" alt="Preview" />
        ) : (
          imageUrl && <Image src={imageUrl} h={300} w="auto" />
        )}

        <Input
          type="file"
          id="image"
          accept="image/*"
          onChange={handleImageChange}
        />

        <Editor
          init={tinymceInit}
          value={content}
          onEditorChange={handleContentChange}
        />

        <Select
          placeholder="Private"
          label="Visibility"
          id="visibility"
          value={visibility}
          onChange={handleVisibilityChange}
          data={['Private', 'Friends', 'Public']}
        />
        <TagPickerTool selectedTags={tags} setSelectedTags={setTags} />
        <Button type="submit">Update Post</Button>
      </form>
    </div>
  )
}

export default PostEdit
