import React from 'react'
import {
  Card,
  Group,
  BackgroundImage,
  Stack,
  Text,
  Center,
  ActionIcon
} from '@mantine/core'
import { Post } from './Post'
import { FaEdit, FaTrashAlt, FaEye } from 'react-icons/fa'
import { useNavigate, Link } from 'react-router-dom'
import TagList from './../Tags/TagList'
import classes from './PostCard.module.css'

interface Props {
  post: Post
  isAdmin?: boolean
  handleDelete: (id: string) => void
}

const PostCard: React.FC<Props> = ({ post, isAdmin, handleDelete }) => {
  // const createdAt = new Date(post.createdAt.seconds * 1000).toLocaleDateString(
  //   'en-US'
  // )
  const navigate = useNavigate()

  return (
    <Card
      key={post.id}
      shadow="sm"
      padding="sm"
      radius="sm"
      withBorder
      h={330}
      w={330}
      className={classes.postcard}
    >
      <Card.Section>
        <Link to={`post/${post.id}`}>
          <BackgroundImage src={post.imageUrl}>
            <Stack h={330} justify="space-between" gap="md">
              <Center p="md">
                <Text className={classes.posttitle} lineClamp={4}>
                  {post.title}
                </Text>
              </Center>
              <Center>
                <Group>
                  <Stack>
                    <Group>
                      <TagList tags={post.tags} />
                    </Group>

                    {isAdmin && (
                      <Group>
                        <ActionIcon
                          onClick={() => navigate(`edit-post/${post.id}`)}
                        >
                          <FaEdit />
                        </ActionIcon>
                        <ActionIcon onClick={() => handleDelete(post.id)}>
                          <FaTrashAlt />
                        </ActionIcon>
                        {post.visibility === 'Public' && (
                          <FaEye style={{ color: 'white' }} />
                        )}
                      </Group>
                    )}
                  </Stack>
                </Group>
              </Center>
            </Stack>
          </BackgroundImage>
        </Link>
      </Card.Section>
    </Card>
  )
}

export default PostCard
