import React from 'react'
// import { Paper, Group, Container, Image, Tabs, Text } from '@mantine/core'
import { Paper, Tabs } from '@mantine/core'
import ReactMarkdown from 'react-markdown'
// import UofCLogo from '../../assets/UofCLogo2.png'
// import GATechLogo from '../../assets/GATechLogo.png'
// import EvanPortfolio from '../../assets/AboutMeLogo.png'
import EvanResume from '../../assets/resume.pdf'
import EvanResumeMd from '../../assets/resume.md'
import { FaCode, FaFilePdf } from 'react-icons/fa'

const Portfolio: React.FC = (): JSX.Element => (
  <Tabs color="green" variant="outline" defaultValue="pdf">
    <Tabs.List>
      <Tabs.Tab value="pdf" leftSection={<FaFilePdf />}>
        PDF
      </Tabs.Tab>
      <Tabs.Tab value="interactive" leftSection={<FaCode />}>
        Portfolio
      </Tabs.Tab>
    </Tabs.List>

    <Tabs.Panel value="pdf">
      <embed
        src={EvanResume}
        type="application/pdf"
        width="100%"
        height="600px"
      />
    </Tabs.Panel>
    <Tabs.Panel value="interactive">
      <Paper shadow="sm" radius="md">
        {/* <Group> */}
        {/*   <h1>Evan Loughlin</h1> */}
        {/*   <Container> */}
        {/*     <Image */}
        {/*       src={EvanPortfolio} */}
        {/*       radius="xl" */}
        {/*       style={{ */}
        {/*         maxWidth: '30%', */}
        {/*         maxHeight: '100%', */}
        {/*         float: 'left', */}
        {/*         marginRight: '20px' */}
        {/*       }} */}
        {/*     /> */}
        {/*     <Text> */}
        {/*       I am a former Civil / Structural Engineer (4 Years), now Software */}
        {/*       Engineer (5 years), MSc Student at Georgia Tech in Machine */}
        {/*       Learning, and Professional Engineer (P.Eng) registered in Alberta, */}
        {/*       Canada. I’m passionate about all things computer science and am */}
        {/*       constantly seeking to learn and expand my understanding of the */}
        {/*       world. */}
        {/*     </Text> */}
        {/*   </Container> */}
        {/* </Group> */}
        {EvanResumeMd}
        <ReactMarkdown>{EvanResumeMd}</ReactMarkdown>
      </Paper>
    </Tabs.Panel>
  </Tabs>
)

export default Portfolio
