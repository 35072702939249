import { Loader } from '@mantine/core'

function LoadingSpinner(): JSX.Element {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <Loader color="blue" type="dots" />
    </div>
  )
}

export default LoadingSpinner
