import { useSearchParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  query,
  where
} from 'firebase/firestore'
import type { Post } from './Post'
import { PostCard } from '.'
import type { Tag } from '../Tags/Tag'
import { User } from 'firebase/auth'
import { modals } from '@mantine/modals'
import { db } from './../../config/firebase'
import {
  Input,
  MultiSelect,
  Flex,
  Text,
  Grid,
  ActionIcon,
  Divider,
  Center,
  Image,
  Stack
} from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { useLoading, LoadingSpinner } from './../LoadingContext/'
import { FaSearch, FaPlus } from 'react-icons/fa'
import UnderConstruction from './../../assets/UnderConstruction.png'

interface PostListProps {
  user: User | null
}

const PostList: React.FC<PostListProps> = ({ user }): JSX.Element => {
  const { loading, setLoading } = useLoading()
  const navigate = useNavigate()
  const [postList, setPostList] = useState<Post[]>([])

  const [allTags, setAllTags] = useState<Tag[]>([])

  const [searchParams, setSearchParams] = useSearchParams()

  const currentQueryParamTags = searchParams.getAll('tags')

  const [selectedTags, setSelectedTags] = useState<string[]>(
    currentQueryParamTags
  )

  const postListCollectionRef = collection(db, 'posts')
  const tagListCollectionRef = collection(db, 'tags')

  const searchTitleEnabled = false

  const getPostList = async (): Promise<void> => {
    try {
      setLoading(true)
      const whereClauses = []

      const queryParamTags = searchParams.get('tags')
        ? searchParams.get('tags')!.split(',')
        : []

      // Query selected tags if there are any
      if (queryParamTags.length > 0) {
        whereClauses.push(where('tags', 'array-contains-any', queryParamTags))
      }

      if (user == null) {
        whereClauses.push(where('visibility', '==', 'Public'))
      }
      const queryRef = query(postListCollectionRef, ...whereClauses)
      const data = await getDocs(queryRef)

      const filteredData: Post[] = data.docs.map((doc) => {
        const docData = doc.data()
        const post: Post = {
          id: doc.id,
          title: docData.title,
          content: docData.content,
          imageUrl: docData.imageUrl,
          visibility: docData.visibility,
          tags: docData.tags,
          createdAt: docData.createdAt,
          updatedAt: docData.updatedAt
        }
        return post
      })
      setPostList(filteredData)
      setLoading(false)
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  const getTagList = async (): Promise<void> => {
    try {
      setLoading(true)
      const data = await getDocs(tagListCollectionRef)
      const filteredData: Tag[] = data.docs.map((doc) => {
        const docData = doc.data()
        const tag: Tag = {
          name: docData.name
        }
        return tag
      })
      setAllTags(filteredData)
      setLoading(false)
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  function onSearchClick() {
    if (selectedTags.length > 0) {
      searchParams.set('tags', selectedTags.join(','))
      setSearchParams(searchParams)
    } else {
      searchParams.delete('tags')
      setSearchParams(searchParams)
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const queryParamTags = searchParams.get('tags')
          ? searchParams.get('tags')!.split(',')
          : []

        setSelectedTags(queryParamTags)

        await getPostList()
        await getTagList()
        setLoading(false)
      } catch (err) {
        console.error(err)
        setLoading(false)
      }
    }

    fetchData()
  }, [searchParams, user])

  const confirmDeleteModal = (postId: string) =>
    modals.openConfirmModal({
      title: 'Are you sure you want to delete this post?',
      children: <Text size="sm">This will permanently delete the post.</Text>,
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => handleDelete(postId)
    })
  const handleDelete = async (postId: string) => {
    try {
      setLoading(true)
      await deleteDoc(doc(db, 'posts', postId))
      setLoading(false)
      navigate('/blog', { replace: true, state: { refresh: true } })
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {user && (
            <ActionIcon onClick={() => navigate('/blog/create-post')}>
              <FaPlus />
            </ActionIcon>
          )}
          {searchTitleEnabled && (
            <Input
              placeholder="Search"
            // onChange={(event) => setSearchInput(event.currentTarget.value)}
            />
          )}

          <Grid columns={12}>
            <Grid.Col span={11}>
              <MultiSelect
                data={allTags.map((tag) => tag.name)}
                placeholder="Search tags..."
                searchable
                clearButtonProps={{ 'aria-label': 'Clear selection' }}
                clearable
                value={selectedTags}
                onChange={(value) => setSelectedTags(value)}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <ActionIcon onClick={onSearchClick}>
                <FaSearch />
              </ActionIcon>
            </Grid.Col>
          </Grid>
          <Divider my="md" />
          {postList.length === 0 ? (
            <Center>
              <Stack>
                <Text size="xl">
                  Oops! Looks like there&apos;s no content yet here... I&apos;m
                  working on it, I promise! 😅
                </Text>
                <Image src={UnderConstruction} radius="xl" h={300} w={300} />
              </Stack>
            </Center>
          ) : (
            <Flex justify="center" wrap="wrap" direction="row" gap="md">
              {postList.map((post) => (
                <PostCard
                  key={post.id}
                  post={post}
                  handleDelete={confirmDeleteModal}
                  isAdmin={user ? true : false}
                />
              ))}
            </Flex>
          )}
        </>
      )}
    </div>
  )
}
export default PostList
