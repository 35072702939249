import '@mantine/core/styles.css'
import './App.css'
import React from 'react'
import { useEffect, useState } from 'react'
// import { useEffect } from 'react'
import theme from './theme/theme'
import EvanLogo from './assets/EvanLogo.svg'
import { useDisclosure } from '@mantine/hooks'
import {
  AppShell,
  MantineProvider,
  Burger,
  Group,
  Image,
  Title,
  ActionIcon,
  Center,
  Container
} from '@mantine/core'
import { FaGithub, FaLinkedin } from 'react-icons/fa'
import { Auth } from './components/Auth'
import { Home } from './components/Home'
import { TodoList } from './components/TodoList'
import { Portfolio } from './components/Portfolio'
import { PostList, PostCreate, PostView, PostEdit } from './components/Posts'
import { LoadingProvider } from './components/LoadingContext'
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { NavButtonList } from './components/NavButtonList'
// import hljs from 'highlight.js'
// import 'highlight.js/styles/github.css'

const App: React.FC = (): JSX.Element => {
  const [opened, { toggle, close }] = useDisclosure()

  const handleLinkClick = () => {
    close()
  }

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768)

  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth <= 768)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return (
    <LoadingProvider>
      <Router>
        <Auth>
          {(user, signInWithGoogle, logout) => (
            <MantineProvider theme={theme}>
              <AppShell
                header={{ height: 120 }}
                navbar={{
                  width: 200,
                  breakpoint: 'sm',
                  collapsed: { desktop: true, mobile: !opened }
                }}
                padding="md"
              >
                <Center></Center>
                <AppShell.Header>
                  <Group
                    h="100%"
                    px="md"
                    style={{ flexWrap: 'nowrap' }}
                  // justify="space-between"
                  >
                    <Burger
                      opened={opened}
                      onClick={toggle}
                      hiddenFrom="sm"
                      size="sm"
                    />
                    <Group
                      style={{
                        flexWrap: 'nowrap',
                        flex: 1
                      }}
                    >
                      <Link to="/">
                        <Image src={EvanLogo} alt="Logo" w={100} h="auto" />
                      </Link>
                      <Center>
                        <Title>evan loughlin</Title>
                      </Center>
                      <ActionIcon
                        component="a"
                        href="https://github.com/e-loughlin"
                        color="black"
                      >
                        <FaGithub size={30} />
                      </ActionIcon>

                      <ActionIcon
                        component="a"
                        href="https://linkedin.com/in/eloughlin/"
                      >
                        <FaLinkedin size={30} />
                      </ActionIcon>
                    </Group>
                    <Group
                      ml="xl"
                      gap={0}
                      visibleFrom="sm"
                      style={{ flexWrap: 'nowrap' }}
                    >
                      <NavButtonList
                        user={user}
                        handleLinkClick={handleLinkClick}
                        signInWithGoogle={signInWithGoogle}
                        logout={logout}
                      />
                    </Group>
                  </Group>
                </AppShell.Header>

                <AppShell.Navbar p="md" px={4}>
                  <NavButtonList
                    user={user}
                    handleLinkClick={handleLinkClick}
                    signInWithGoogle={signInWithGoogle}
                    logout={logout}
                  />
                </AppShell.Navbar>

                <AppShell.Main>
                  <Container size={isMobile ? '100%' : '80%'}>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      {user && <Route path="/todo" element={<TodoList />} />}
                      {user && (
                        <Route
                          path="blog/create-post"
                          element={<PostCreate />}
                        />
                      )}
                      {user && (
                        <Route
                          path="blog/edit-post/:id"
                          element={<PostEdit />}
                        />
                      )}
                      <Route path="/portfolio" element={<Portfolio />} />
                      <Route path="/blog" element={<PostList user={user} />} />
                      <Route
                        path="blog/post/:id"
                        element={<PostView user={user} />}
                      />{' '}
                    </Routes>
                  </Container>
                </AppShell.Main>
              </AppShell>
            </MantineProvider>
          )}
        </Auth>
      </Router>
    </LoadingProvider>
  )
}

export default App
