import { Input } from '@mantine/core'
import { addDoc, collection, deleteDoc, doc, getDocs } from 'firebase/firestore'
import { db } from './../../config/firebase'
import { useEffect, useState } from 'react'
import { FaTrash } from 'react-icons/fa'

export const TodoList = () => {
  const [todoList, setTodoList] = useState<
    { id: string; title: string; description: string }[]
  >([])

  const [newTodoTitle, setNewTodoTitle] = useState('')
  const [newTodoDescription, setNewTodoDescription] = useState('')
  const todoListCollectionRef = collection(db, 'todos')
  const getTodoList = async () => {
    try {
      const data = await getDocs(todoListCollectionRef)
      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      }))
      setTodoList(filteredData)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getTodoList()
  }, [])

  const onSubmitTodo = async () => {
    try {
      await addDoc(todoListCollectionRef, {
        title: newTodoTitle,
        description: newTodoDescription
      })
      setNewTodoTitle('')
      setNewTodoDescription('')
      getTodoList()
    } catch (err) {
      console.error(err)
    }
  }

  const deleteTodo = async (id: string) => {
    const todo = doc(db, 'todos', id)
    try {
      await deleteDoc(todo)
      getTodoList()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div>
      <div>
        <div>
          <Input
            placeholder="Title"
            value={newTodoTitle}
            onChange={(e) => setNewTodoTitle(e.target.value)}
          />
          <Input
            placeholder="Description"
            value={newTodoDescription}
            onChange={(e) => setNewTodoDescription(e.target.value)}
          />
          <button onClick={onSubmitTodo}>Create TODO</button>
        </div>
        <h2>TODO List:</h2>
        <ul>
          {todoList.map((todo) => (
            <li key={todo.id}>
              <strong>{todo.title}</strong>: {todo.description}
              <button onClick={async () => await deleteTodo(todo.id)}>
                <FaTrash />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default TodoList
