import { Timestamp, QueryDocumentSnapshot } from 'firebase/firestore'

export class Post {
  id: string
  title: string
  content: string
  visibility: string
  imageUrl: string
  tags: string[]
  createdAt: Timestamp
  updatedAt: Timestamp
  // eventDate: Timestamp | null

  constructor(
    id: string,
    title: string,
    content: string,
    visibility: string,
    imageUrl: string,
    tags: string[],
    createdAt: Timestamp,
    updatedAt: Timestamp
    // eventDate: Timestamp | null
  ) {
    this.id = id
    this.title = title
    this.content = content
    this.visibility = visibility
    this.imageUrl = imageUrl
    this.tags = tags
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    // this.eventDate = eventDate
  }

  toString(): string {
    return (
      this.id +
      ', ' +
      this.title +
      ', ' +
      this.content +
      ', ' +
      this.visibility +
      ', ' +
      this.imageUrl +
      ', ' +
      this.tags +
      ', ' +
      this.createdAt +
      ', ' +
      this.updatedAt
      // ', ' +
      // this.eventDate
    )
  }
}

// Firestore data converter
export const postConverter = {
  toFirestore: (post: Post) => {
    return {
      id: post.id,
      title: post.title,
      content: post.content,
      visibility: post.visibility,
      imageUrl: post.imageUrl,
      tags: post.tags.map((tag) => tag),
      createdAt: post.createdAt,
      updatedAt: post.updatedAt
    }
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot): Post => {
    const data = snapshot.data()
    return new Post(
      data.id,
      data.title,
      data.content,
      data.visibility,
      data.imageUrl,
      data.tags,
      data.createdAt,
      data.updatedAt
      // data.eventDate
    )
  }
}
