import { Text } from '@mantine/core'

import classes from './NavButton.module.css'

interface Props {
  content: string
  active: boolean
}

const NavButton: React.FC<Props> = ({ content, active }) => {
  return (
    <>
      <Text className={classes.navbutton} c={active ? 'black' : 'dimmed'}>
        {content}
      </Text>
    </>
  )
}

export default NavButton
