import { useState, ChangeEvent, FormEvent } from 'react'
import imageCompression from 'browser-image-compression'
import { Editor } from '@tinymce/tinymce-react'
import tinymceInit from './../../config/tinymce-init'
import { TextInput, Button, Select, Text, Image } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { TagPickerTool } from '../Tags'
import { collection, addDoc } from 'firebase/firestore'
import { db } from './../../config/firebase'
import { useNavigate } from 'react-router-dom'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { useLoading, LoadingSpinner } from './../LoadingContext'
import { Timestamp } from 'firebase/firestore'

const storage = getStorage()
const storageRef = ref(storage)

const PostCreate = () => {
  const { loading, setLoading } = useLoading()
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [visibility, setVisibility] = useState('')
  const [image, setImage] = useState<File | null>(null)
  const [imagePreview, setImagePreview] = useState<string>('')
  const [tags, setTags] = useState<string[]>([])
  const [eventDate, setEventDate] = useState<Date | null>()
  const navigate = useNavigate()

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
  }

  const handleContentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setContent(event)
  }

  const handleVisibilityChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVisibility(event)
  }

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        // Set the image preview URL
        setImage(file)
        setImagePreview(e.target?.result as string)
      }
      reader.readAsDataURL(file)
    } else {
      setImage(null)
      setImagePreview('')
    }
  }

  const handleEventDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(event)
    setEventDate(event)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const newDate = new Date()
    const createdAt = Timestamp.fromDate(newDate)
    const updatedAt = Timestamp.fromDate(newDate)

    // let eventDateFirestore: firebase.firestore.Timestamp | null = null
    // if (eventDate != null && eventDate !== undefined) {
    //   eventDateFirestore = firebase.firestore.Timestamp.fromDate(eventDate)
    // }

    try {
      // Upload image to Firebase Storage if an image is selected
      let imageUrl = null
      if (image) {
        const compressedImage = await imageCompression(image, {
          maxSizeMB: 0.2, // specify the maximum size of the compressed image
          maxWidthOrHeight: 1000, // specify the maximum width or height of the compressed image
          useWebWorker: true // use web worker for faster compression
        })
        const imageRef = ref(storageRef, `images/${image.name}`)
        setLoading(true)
        await uploadBytes(imageRef, compressedImage)
        setLoading(false)
        imageUrl = await getDownloadURL(imageRef)
      }

      // Add post to Firestore
      setLoading(true)
      const docRef = await addDoc(collection(db, 'posts'), {
        title,
        content,
        visibility,
        imageUrl,
        tags,
        createdAt,
        updatedAt
        // eventDate: eventDateFirestore
      })
      setLoading(false)

      navigate(`/blog/post/${docRef.id}`)
    } catch (error) {
      console.error('Error adding document:', error)
    }
  }
  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <h2>Create a New Post</h2>
          <form onSubmit={handleSubmit}>
            <TextInput
              label="Title"
              id="title"
              value={title}
              onChange={handleTitleChange}
            />
            <Text>Image Preview</Text>
            {imagePreview && (
              <Image src={imagePreview} h={300} w="auto" alt="Preview" />
            )}
            <input
              type="file"
              id="image"
              accept="image/*"
              onChange={handleImageChange}
            />

            <Editor
              init={tinymceInit}
              value={content}
              onEditorChange={handleContentChange}
            />

            <Select
              placeholder="Private"
              label="Visibility"
              id="visibility"
              value={visibility}
              onChange={handleVisibilityChange}
              data={['Private', 'Friends', 'Public']}
            />
            <TagPickerTool selectedTags={tags} setSelectedTags={setTags} />
            <DatePicker
              id="eventDate"
              value={eventDate}
              onChange={handleEventDateChange}
            />
            <Button type="submit">Create Post</Button>
          </form>
        </>
      )}
    </div>
  )
}

export default PostCreate
