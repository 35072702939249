import { useEffect, useState } from 'react'
import { MultiSelect, TextInput, Button } from '@mantine/core'
import { collection, getDocs, addDoc } from 'firebase/firestore'
import { db } from './../../config/firebase'

function TagPickerTool({
  selectedTags,
  setSelectedTags
}: {
  selectedTags: string[]
  setSelectedTags: (tags: string[]) => void
}) {
  const [tagList, setTagList] = useState<string[]>([])
  const [newTagName, setNewTagName] = useState('')
  const [tagsUpdated, setTagsUpdated] = useState<boolean>(false)

  const getTagList = async (): Promise<void> => {
    try {
      const tagListCollectionRef = collection(db, 'tags')
      const data = await getDocs(tagListCollectionRef)
      const filteredData: string[] = data.docs.map((doc) => {
        const docData = doc.data()
        return docData.name
      })
      setTagList(filteredData)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getTagList()
      } catch (err) {
        console.error(err)
      }
    }

    fetchData()
  }, [tagsUpdated])

  const handleAddTag = async () => {
    if (newTagName !== '') {
      await addDoc(collection(db, 'tags'), { name: newTagName })
      setTagsUpdated(true)
      setNewTagName('')
    }
  }

  return (
    <>
      <TextInput
        value={newTagName}
        onChange={(event) => setNewTagName(event.currentTarget.value)}
        placeholder="Enter new tag name"
      />
      <Button onClick={handleAddTag}>Add Tag</Button>
      <MultiSelect
        data={tagList}
        label="Tags"
        placeholder="Search tags..."
        searchable
        clearButtonProps={{ 'aria-label': 'Clear selection' }}
        clearable
        value={selectedTags}
        onChange={setSelectedTags}
      />
    </>
  )
}

// TODO: Implement Tags ->  Update / Delete / etc

export default TagPickerTool
