import { Link, useLocation } from 'react-router-dom'
import { User } from 'firebase/auth'
import { FaUser, FaSignOutAlt } from 'react-icons/fa'
import NavButton from './NavButton'

interface Props {
  user: User | null
  handleLinkClick: () => void
  signInWithGoogle: () => void
  logout: () => void
}

const NavButtonList: React.FC<Props> = ({
  user,
  handleLinkClick,
  signInWithGoogle,
  logout
}) => {
  const location = useLocation()

  const isActive = (path: string) => {
    return location.pathname == path
  }

  const handleLinkClickWrapper = () => {
    handleLinkClick()
  }

  return (
    <>
      <Link to="/" style={{ padding: 5 }} onClick={handleLinkClickWrapper}>
        <NavButton content={'Home'} active={isActive('/')} />
      </Link>
      {user && (
        <Link
          to="/todo"
          style={{ padding: 5 }}
          onClick={handleLinkClickWrapper}
        >
          <NavButton content={'Todo List'} active={isActive('/todo')} />
        </Link>
      )}
      <Link
        to="/portfolio"
        style={{ padding: 4 }}
        onClick={handleLinkClickWrapper}
      >
        <NavButton content={'Portfolio'} active={isActive('/portfolio')} />
      </Link>
      <Link to="/blog" style={{ padding: 5 }} onClick={handleLinkClickWrapper}>
        <NavButton content={'Blog'} active={isActive('/blog')} />
      </Link>
      {user ? (
        <FaSignOutAlt onClick={logout} />
      ) : (
        <FaUser onClick={signInWithGoogle} />
      )}
    </>
  )
}

export default NavButtonList
